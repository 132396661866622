<template>
  <!-- <div class="certificate-wrap">
    <div class="certificate">
      <div class="id">编号：2022080288</div>
      <div class="name">张浩天</div>
      <img src="../../src/assets/images/certificate.jpg">
      <button class="btn">点击保存志愿者证书到手机相册</button>
    </div>
    
  </div> -->
  <div>
    <div>
      <img :src="src" alt="" mode="widthFix" class="saveImage" />
      <div class="tips">长按保存志愿者证书到手机相册</div>
    </div>
    <vue-canvas-poster
      :painting="painting"
      @success="success"
      @fail="fail"
    ></vue-canvas-poster>
  </div>
</template>

<script>
import { VueCanvasPoster } from "vue-canvas-poster";
export default {
  components: {
    VueCanvasPoster,
  },
  data() {
    return {
      name: "",
      code: "",
      src: "",
      painting: {},
    };
  },
  created() {
    console.log("created");
    if (this.$route.params.name) {
      this.name = this.$route.params.name;
      this.code = this.$route.params.code;
    }
  },
  mounted() {
    window.addEventListener("load", () => {
      this.$router.back();
    });
    console.log("mounted");
    this.painting = {
      width: "750px",
      height: "1061px",
      background: "#fff",
      views: [
        {
          type: "image",
          url: require("../assets/images/certificate.jpg"),
          css: [
            {
              top: "0px",
              left: "0px",
              width: "750px",
              height: "1061px",
            },
          ],
        },
        {
          type: "text",
          text: "编号：" + this.code,
          css: [
            {
              top: "70px",
              right: "80px",
              fontSize: "20px",
            },
          ],
        },
        {
          type: "text",
          text: "尊敬的",
          css: [
            {
              top: "444px",
              left: "135px",
              fontSize: "20px",
            },
          ],
        },
        {
          type: "text",
          text: this.name,
          css: [
            {
              top: "444px",
              left: "215px",
              fontSize: "20px",
            },
          ],
        },
        {
          type: "rect",
          css: [
            {
              height: "1px",
              width: "85px",
              color: "#000",
              left: "205px",
              top: "474px",
            },
          ],
        },
      ],
    };
  },
  methods: {
    toSaveCertificate() {},
    toSave() {
      const save_link = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
      );
      save_link.href = this.src; // 画好后的base64图片，从convertCanvasToImage方法得到的，不能太大了，应该是不能超过10万字符，超过了，就下载失败了
      save_link.download = "certificate.png"; // 自定义图片的名字
      const event = document.createEvent("MouseEvents");
      event.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      save_link.dispatchEvent(event);
    },
    success(res) {
      this.src = res;
      console.log(res);
    },
    fail(err) {
      console.log(err);
    },
  },
};
</script>

<style>
.content {
  width: 100%;
  height: auto;
}
.saveImage {
  width: 100vw;
  height: auto;
}
.tips {
  color: #f0c62c;
  margin-top: 20px;
}
</style>
