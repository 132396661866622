<template>
  <div class="form-wrap">
    <div class="form-content">
      <div class="title">
        <img src="../../src/assets/images/title.png" />
      </div>
      <div class="form">
        <input
          type="text"
          v-model="name"
          class="input-style"
          placeholder="请输入您的真实姓名"
        />
        <input
          type="number"
          v-model="mobile"
          class="input-style"
          placeholder="请输入您的手机号"
        />
        <!-- <button class="btn" @click="commitInfo">确认提交</button> -->
        <button class="btn" @click="commitInfo">确认提交</button>
      </div>
      <div class="hand">
        <img src="../../src/assets/images/hand.png" />
      </div>
      <!-- <div class="copyright">主办单位: 共青团新乐市委</div> -->
    </div>
  </div>
</template>

<script>
import { commitForm } from "@/network/API.js";
export default {
  name: "EnterInfo",
  data() {
    return {
      name: "",
      mobile: "",
      code: "",
    };
  },
  methods: {
    commitInfo() {
      var txt1 = "您的证书正在准备中…";
      var txt2 = "先看看团团其他的精彩活动吧！";
      this.$toast(`<div>${txt1}</div><div>${txt2}</div>`);

      return;
      console.log(process.env.NODE_ENV);
      if (process.env.NODE_ENV == "development") {
        this.mobile = "13313115201";
        this.name = "测试者";
      }

      commitForm({
        mobile: this.mobile,
        name: this.name,
      })
        .then((res) => {
          console.log(res);
          if (res.status == 1) {
            this.$router.push({
              name: "Cer",
              params: { name: this.name, code: res.number },
            });
          } else {
            this.$toast("未获取证书资格");
          }
        })
        .catch((err) => {
          this.$toast("未获取证书资格");
        });
    },
  },
};
</script>

<style>
.form-wrap {
  background: url(../assets/images/body.jpg) center center no-repeat;
  background-size: cover;
}
.et-wrapper.et-center {
  top: 32%;
}
.et-wrapper {
  background-color: rgba(red, green, blue, alpha);
  min-width: 260px !important;
}
</style>
