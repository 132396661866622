import Vue from 'vue'
import axios from 'axios'
import QS from 'qs';
import Router from '@/router/index'


let baseURL

if (process.env.NODE_ENV === 'development') {

  baseURL = '/api'
} else {
  if (process.env.type === 'test') {
    baseURL = 'http://angel.inewsqq.com/'
  } else {
    baseURL = 'http://angel.inewsqq.com/'
  }
}
// axios.defaults.timeout = 6000;                
// axios.defaults.baseURL = baseURL;
// axios.defaults.responseType = "json";
// axios.defaults.withCredentials = false;

const service = axios.create({
  baseURL: baseURL, // api base_url,
  timeout: 60 * 1000, // 请求超时时间,
  headers: {
    "Content-Type": "application/json"
  },
});

service.interceptors.request.use(
  config => {
    var token = window.localStorage.getItem("token")
    if (token && token != "") {
      config.headers.token = token
    }
    return config
  },
  error => {
    return Promise.reject(error);
  }
)

service.interceptors.response.use(
  response => {
    if (response.status == 200) {
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response.errMsg)
    }
  }
)



export function get(url, params) {
  return new Promise((resolve, reject) => {
    service.get(url, {
        params: params
      })
      .then(res => {
        resolve(res);
      })
      .catch(msg => {
        reject(msg)
      })
  });
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    service.post(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err)
      })
  });
}