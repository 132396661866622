import Vue from "vue";
import VueRouter from "vue-router";
import InfoView from "../views/EnterInfo.vue";
import CertificateView from "../views/Certificate.vue";


Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Info",
    component: InfoView,
  },
  {
    path: "/cer",
    name: "Cer",
    component: CertificateView
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});

export default router;