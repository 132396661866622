import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '../src/assets/style.css';

import Toast from 'vue-easy-toast'
Vue.use(Toast, {
  duration: 1500,
  horizontalPosition: 'center',
  verticalPosition: 'center'
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')